import api from "../../../base/utils/request";

export const getPageList = (data) => {
  return api({
    url: '/admin/app_design/page/index',
    method: 'post',
    data
  })
}

export const getPageDetail = (data) => {
  return api({
    url: '/admin/app_design/page/configDetail',
    method: 'post',
    data,
    notCancel: true //关闭取消重复请求功能
  })
}

export const savePageDetail = (data) => {
  return api({
    url: '/admin/app_design/page/saveConfig',
    method: 'post',
    data
  })
}

// 获取广告列表
export const advertList = (data, notCancel = true) => {
  return api({
    url: "/admin/cyc/banner/componentSearch",
    method: "post",
    data,
    notCancel
  });
};
// 获取广播消息列表
export const getBroadcast = (data) => {
  return api({
    url: "/admin/cyc/broadcastMessage/index",
    method: "post",
    data
  });
};


export const createPage = (data) => {
  return api({
    url: '/admin/app_design/page/create',
    method: 'post',
    data
  })
}

export const deletePage = (data) => {
  return api({
    url: '/admin/app_design/page/del',
    method: 'post',
    data
  })
}

export const updateLevelAndSort = (data) => {
  return api({
    url: '/admin/app_design/page/updateLevelAndSort',
    method: 'post',
    data
  })
}

export const renamePage = (data) => {
  return api({
    url: '/admin/app_design/page/rename',
    method: 'post',
    data
  })
}

/**
 * 同步正式版（发布）
 * @param{Object} data
 */
export const syncProduct = (data) => {
  return api({
    url: '/admin/app_design/page/syncProduct',
    method: 'post',
    data
  })
}

// 获取预览二维码
export const PreviewCode = (data) => {
  return api({
    url: '/admin/app_design/page/preview',
    method: 'post',
    data
  })
}

/**
 * 获取系统设置
 */
export const getSystemSettings = data => {
  return api({
    url: '/admin/app_design/page/systemPage',
    method: 'post',
    data
  })
}

/**
 * 获取模板库列表
 */
export const TemplateList = (data) => {
  return api({
    url: '/admin/app_design/template/index',
    method: 'post',
    data
  })
}

/**
 * 获取模板库标签
 */
export const TemplateTags = (data) => {
  return api({
    url: '/admin/app_design/template/tags',
    method: 'post',
    data
  })
}

export const backupList = data => {
  return api({
    url: "/admin/app_design/backup/index",
    method: "post",
    data
  })
}

export const backup = data => {
  return api({
    url: '/admin/app_design/backup/create',
    method: 'post',
    data
  })
}

export const deleteBackup = data => {
  return api({
    url: '/admin/app_design/backup/del',
    method: 'post',
    data
  })
}

export const restoreBackup = data => {
  return api({
    url: '/admin/app_design/backup/restore',
    method: 'post',
    data
  })
}

export const records = data => {
  return api({
    url: '/admin/app_design/backup/records',
    method: 'post',
    data
  })
}

export const setCover = data => {
  return api({
    url: '/admin/app_design/page/updateCover',
    method: 'post',
    data
  })
}

/**
 * 获取模板页面
 * @param id    模板id
 * @param code  pageset
 */
export const getTemplatePage = (id, code) => {
  return api({
    url: '/admin/app_design/template/page',
    method: 'post',
    data: {
      template_id: id,
      page_code: code
    }
  })
}

export const applyTemplate = data => {
  return api({
    url: '/admin/app_design/page/applyTemplate',
    method: 'post',
    data
  })
}

export const setIndex = data => {
  return api({
    url: '/admin/app_design/page/setCustomIndex',
    method: 'post',
    data
  })
}
// 项目检查小程序状态
export const checkMpWxStatus = data => {
  return api({
    url: '/admin/app_design/project/checkMpStatus',
    method: 'post',
    data
  })
}
// 撤回审核
export const undoCommit = data => {
  return api({
    url: '/admin/wx_open/wx_applet_version/undoCommit',
    method: 'post',
    data
  })
}

/* 切换新春主题 */
export const switchYearTheme = (data) => {
  return api({
    url: '/admin/app_design/page/changeStyle',
    method: 'post',
    data
  })
}
