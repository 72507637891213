export default [
  {
    path: "system-admin", name: "system-admin", component: () => import("../views/index"), meta: { title: "" },
    redirect: { name: "AdminWebSettings" },
    children: [
      {path: 'watermark-config', name: "AdminWatermarkConfig", component: () => import("../views/watermark-config"),
        // meta: {title: "水印设置"}
        },
      { path: "web-settings", name: "AdminWebSettings", component: () => import("../views/web-settings"), meta: { title: "后台配置" } },
      { path: "secure-config", name: "AdminSecurityConfig", component: () => import("../views/secure-config"), meta: { title: "安全配置" } },
      { path: "wechat-config", name: "AdminWechatConfig", component: () => import("../views/wechat-config"), meta: { title: "微信支付配置" } },
      { path: "sms-config", name: "AdminSmsConfig", component: () => import("../views/sms-config"), meta: { title: "短信配置" } },
      { path: "native-config", name: "AdminNativeConfig", component: () => import("../views/native-config"), meta: { title: "籍贯配置" } },
      { path: "subscribe-config", name: "AdminSubscribeConfig", component: () => import("../views/subscribe-config"), meta: { title: "订阅消息" } },
      { path: "admin-start-page", name: "AdminStartPage", component: () => import("../views/admin-start-page"), meta: { title: "启动页" } },
      { path: "admin-user-log", name: "AdminUserLog", component: () => import("../views/admin-user-log"), meta: { title: "操作日志" } },
      { path: "cyc-login-page", name: "AdminLoginPage", component: () => import("../views/cyc-login-page"), meta: { title: "登录页" } },
    ]
  },
  {
    path: "cyc-content", component: { render: h => h("router-view") },
    name: "CycContent",
    redirect: { name: "CycContentIndex" },
    // meta: { title: "单页面管理", breadcrumb: { name: "CycContent" }, activeMenu: "CycContent" },
    children: [
      {
        path: "content", name: "CycContentIndex",
        component: () => import("../views/cyc-content/content"),
      },
      {
        path: "add/:id", name: "AddContent",
        component: () => import("../views/cyc-content/add"),
        meta: { title: "新增", fitContent: true }
      },
      {
        path: "edit/:id", name: "EditContent",
        component: () => import("../views/cyc-content/add"),
        meta: { title: "编辑", fitContent: true }
      },
    ]
  }
]
