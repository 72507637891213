/*
 * @Author: mulingyuer
 * @Date: 2021-08-23 17:32:02
 * @LastEditTime: 2021-09-07 11:37:15
 * @LastEditors: mulingyuer
 * @Description:在线课堂路由入口
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\router\index.js
 * 怎么可能会有bug！！！
 */
import materialManage from "./material-manage";
import courseManage from "./course-manage";

export default [
  {
    path: "online-classroom", component: { render: h => h("router-view") },
    children: [
      {
        path: "material-category-manage", name: "ClassroomMaterialCategoryManage",
        component: () => import(/* webpackChunkName: "online-course" */ "../views/material-category-manage"),
        // meta: { title: "素材分类管理" }
      },
      //素材管理
      ...materialManage,
      //课程分类管理
      {
        path: "course-category-manage", name: "ClassroomCourseCategoryManage",
        component: () => import(/* webpackChunkName: "online-course" */"../views/course-category-manage"),
        // meta: { title: "课程分类管理" }
      },
      //设置
      {
        path: "settings", name: "ClassroomSettings",
        component: () => import(/* webpackChunkName"online-course" */ "../views/settings"),
        // meta: { title: "设置" }
      },
      //课程管理
      ...courseManage,
    ]
  }
]
