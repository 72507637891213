import api from "@/base/utils/request";
import { enCode, timestamp, setFavicon } from '@/base/utils/tool'
import store from "@/base/store";

/**
 * SaaS 后台的全局变量
 */
export default {
  state: {
    "tenant": { // 租户项目全局配置
      "version": "", // 版本（common：普通，school：校友版）
      features: [], // 功能列表 协会互联-admin_gov_data_manage
      project_type: -1
    }
  },
  getters: {
    tenant: state => {
      return state.tenant
    }
  },
  mutations: {
    setStateAttr(state, param) {
      if (param instanceof Array) {
        for (let item of param) {
          state[item.key] = item.val;
        }
      } else {
        state[param.key] = param.val;
      }
    },
  },
  actions: {
    GET_WEB_CONFIG({ commit }) {
      return new Promise((resolve, reject) => {
        api({
          url: "/admin/admin/index/webConfig",
          method: "post",
        }).then(res => {
          const {data: { web_tab_logo: logo, web_tab_title: title, tenant, timestamp: t1 }} = res
          /* 设置网站图标 */
          setFavicon(logo)
          /* 设置网站标题，服务暂停状态，租户全局配置 */
          commit('setStateAttr', [{
            key: 'docTitle', val: title
          }, {
            key: 'isAvailable', val: true
          }, {
            // key: 'tenant', val: {version: 'school'} || tenant
            key: 'tenant', val: tenant
          }])
          store.commit('setStateAttr', [
            {key: 'serverTimestamp', val: t1},
            {key: 'localTimestamp', val: timestamp()}
          ])
          resolve()
        }).catch(err => {
          let routeData = null;
          if (err.code === 20010) {
            routeData = {
              name: "LoginError",
              params: { type: "overdue" },
            };
          } else if (err.code === 20020) {
            routeData = {
              name: "LoginError",
              params: { type: "reviewed" },
            };
          } else if (err.code === 20030) {
            routeData = {
              name: "LoginError",
              params: { type: "nopass" },
            };
          } else if (err.code === 20040) {
            routeData = {
              name: "LoginError",
              params: { type: "disable", errorData: enCode(err.data) },
            };
          } else if (err.code === 11020) {
            routeData = {
              name: "LoginError",
              params: { type: "frozen", errorData: enCode(err.data) },
            };
          }
          if (routeData) {
            store.commit("user/setToken", "");
            // next(routeData)
            // this.$
            // router.replace(routeData).catch(() => {});
            /* 交给路由守卫处理 */
            reject(routeData)
          }
        })
      })
    },
    GET_PUB_KEY() {
      return new Promise((resolve, reject) => {
        if (store.getters.pubKey) {
          resolve(store.getters.pubKey)
          return
        }
        api({
          url: '/admin/admin/index/rsaPublicKey',
          method: 'post'
        }).then(res => {
          let pub = res.data.rsa_public_key
          pub = pub.replace('\n', '')
          store.commit('setStateAttr', {
            key: 'pubKey',
            val: pub
          })
          resolve(pub)
        }).catch((err) => {
          reject(err)
        })
      })
    }
  },
}
