import signIn from "./sign-in"

export default [
  {
    path: "level-point", component: { render: h => h("router-view") },
    // meta: {
    //   title: "等级积分",
    //   breadcrumb: { name: "IncomeSetting" },
    //   activeMenu: "IncomeLevelConfig"
    // },
    children: [
      {
        path: "income-setting", name: "IncomeSetting", component: () => import("../views/income-setting"),
        // meta: { title: "基本设置" }
      },
      {
        path: "income-level-config", name: "IncomeLevelConfig", component: () => import("../views/income-level-config"),
        // meta: { title: "等级设置" }
      },
      {
        path: "task-config", name: "IncomeTask", component: () => import("../views/task-config"),
        // meta: { title: "任务设置" }
      },
      {
        path: "income-records", name: "IncomeRecords", component: () => import("../views/income-records"),
        // meta: { title: "用户记录" }
      }
    ]
  },
  // 签到
  ...signIn
]
